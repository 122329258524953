import React, { Component } from 'react';
import Api from "modEcomerce/ApiEcomerce";
import { Link } from 'react-router-dom';
import HFormUser from "global/components/Page/Forms/HFormUser";
import EstrellasRating from '../estrellas/EstrellasRating'
import Corazon from '../corazon/Corazon'
import "./Card_01_card.css"

class Card_04 extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }


    render = () => {
        return (<>
            <div className="primaryCard card-body1">
                <div className="product-alerts ribbon-wrapper1 ribbon-xl">
                    {this.data.producto.dias_new && (<div className="product-new ribbon">Nuevo</div>)}
                    {this.data.producto.dias_promo && (<div className="product-promotion" >Promocion</div>)}
                </div>
                <Corazon like={this.data.producto.swheart}
                    idModelo={this.data.producto.idmodelo} />
                <Link to={{
                    pathname: Api.productoDetalleRoute + "/" + this.data.producto.idmodelo
                    , state: { prueba: this.data.producto.idmodelo }
                    , key: this.data.producto.idmodelo
                }}
                    onClick={this.data.loadClick}
                    className="no-href-decoration">
                    <div className="secondayCard">

                        <div className="product-image">
                            <div className="product-cover"
                                style={{ backgroundImage: `url(${this.data.producto.http_imagen})` }}
                            ></div>
                        </div>

                        <hr className="m-0" />
                        <div className="product-content">
                            {false && (<small className="product-old-price text-decoration-line-through text-secondary"
                                style={this.data.producto.precio_old !== 0 ? { opacity: 1 } : { opacity: 0 }} >
                                ${this.data.producto.precio_old}
                            </small>)}
                            <div className="product-title"><b>{this.data.producto.titulo}</b></div>
                            <div className="content1" style={{ marginLeft: '15px', marginRight: '15px' }}
                                dangerouslySetInnerHTML={{ __html: this.data.producto.text_descrip }}>
                            </div>
                            {false && (<a href="/prueba1" className="card-link">Card link</a>)}
                            {false && (<a href="/prueba2" className="card-link">Another link</a>)}
                            <div className="product-price fw-light">${this.data.producto.precio_ven_display}</div>

                            <div className="product-stars mt-2">
                                <span>
                                    <EstrellasRating rating={this.data.producto.rating} />
                                </span>
                                <span className="product-amount-rating">({this.data.producto.calif_total}{this.data.producto.calif_total < 2 ? 'Calificación' : 'Calificaciones'})</span>
                            </div>
                        </div>

                    </div>
                </Link>
                <div className="product-add-card" onClick={() => this.data.addCar(this,this.data.producto.idmodelo)}>
                    <i className="fas fa-cart-plus"></i>
                    Agregar al Carro11
                </div>
                {/*<div  className="product-add-card bg-warning" disabled>
        <i className="fas fa-cart-plus"></i>Carregando...
        <div className="m-0 ms-3 spinner-border text-primary spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
    </div>*/}
                {/*<div onClick={() => this.AddToCart()} data-bs-toggle="modal" data-bs-target="#staticBackdrop">{ insideCartBtn }</div>
        <div data-bs-toggle="modal" data-bs-target="#staticBackdrop"><div className="product-add-card"><i className="fas fa-cart-plus" aria-hidden="true"></i>Adicione ao Carrinho
        </div>
      </div>*/}
            </div>

        </>

        )
    }

}
export default Card_04;
