import React,{Component} from 'react';
import EstrellasRating from '../estrellas/EstrellasRating';

class ProdReview extends Component{
	constructor(props) {
     super(props);
     this.data=props;
  } 
  render =()=>{
    return (  
        <div className="tab-pane" id="pd-rev">
        <div className="pd-tab__rev">
            <div className="u-s-m-b-30">
                <div className="pd-tab__rev-score">
                    <div className="u-s-m-b-8">
                        <h2>{this.data.lComentarios.length} Comentarios - {this.data.rating} (General)</h2>
                    </div>
                    <div className="gl-rating-style-2 u-s-m-b-8">
                         <EstrellasRating rating={this.data.rating}/>
                    </div>
                    <div className="u-s-m-b-8">
                        <h4>Te escuchamos</h4>
                    </div>

                    <span className="gl-text">Danos tu Opinión</span>
                </div>
            </div>
            <div className="u-s-m-b-30">
                <form className="pd-tab__rev-f1">
                    <div className="rev-f1__group">
                        <div className="u-s-m-b-15">
                            <h2>{this.data.lComentarios.length} Comentario(s)  para {this.data.prodNombre}</h2>
                        </div>
                        {/*
                        <div className="u-s-m-b-15">
                            <label htmlFor="sort-review"></label><select className="select-box select-box--primary-style" id="sort-review">
                                <option selected>Sort by: Best Rating</option>
                                <option>Sort by: Worst Rating</option>
                            </select>
                        </div>
                        */}
                    </div>
                    <div className="rev-f1__review">
                    {	this.data.lComentarios.map((com, index) => {
                        return (                        
                    <div className="review-o u-s-m-b-15" key={'uuy'+ index}>
                        <div className="review-o__info u-s-m-b-8">
                            <span className="review-o__name">{com.nombre}</span>
                        </div>
                        <div className="review-o__info u-s-m-b-8">
                            <span className="review-o__name">{com.fcomentario}</span>
                        </div>
                        <div className="review-o__rating gl-rating-style u-s-m-b-8">
                                <EstrellasRating rating={com.rating}/>
                                <span>({com.rating})</span>
                        </div>
                        <p className="review-o__text">{com.descripcion}</p>
                    </div>

                                )
                        })
                    }                        
                    </div>
                </form>
            </div>
            <div className="u-s-m-b-30">
                <form className="pd-tab__rev-f2">
                    <h2 className="u-s-m-b-15">Add a Review</h2>

                    <span className="gl-text u-s-m-b-15">Your email address will not be published. Required fields are marked *</span>
                    <div className="u-s-m-b-30">
                        <div className="rev-f2__table-wrap gl-scroll">
                            <table className="rev-f2__table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i>

                                                <span>(1)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i>

                                                <span>(1.5)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i>

                                                <span>(2)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i>

                                                <span>(2.5)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>

                                                <span>(3)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i>

                                                <span>(3.5)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>

                                                <span>(4)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i>

                                                <span>(4.5)</span></div>
                                        </th>
                                        <th>
                                            <div className="gl-rating-style-2"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>

                                                <span>(5)</span></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-1" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-1"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-1.5" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-1.5"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-2" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-2"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-2.5" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-2.5"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-3" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-3"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-3.5" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-3.5"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-4" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-4"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-4.5" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-4.5"></label></div>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="radio-box">

                                                <input type="radio" id="star-5" name="rating"/>
                                                <div className="radio-box__state radio-box__state--primary">

                                                    <label className="radio-box__label" htmlFor="star-5"></label></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="rev-f2__group">
                        <div className="u-s-m-b-15">

                            <label className="gl-label" htmlFor="reviewer-text">YOUR REVIEW *</label><textarea className="text-area text-area--primary-style" id="reviewer-text"></textarea></div>
                        <div>
                            <p className="u-s-m-b-30">

                                <label className="gl-label" htmlFor="reviewer-name">NAME *</label>

                                <input className="input-text input-text--primary-style" type="text" id="reviewer-name"/></p>
                            <p className="u-s-m-b-30">

                                <label className="gl-label" htmlFor="reviewer-email">EMAIL *</label>

                                <input className="input-text input-text--primary-style" type="text" id="reviewer-email"/></p>
                        </div>
                    </div>
                    <div>

                        <button className="btn btn--e-brand-shadow" type="submit">SUBMIT</button></div>
                </form>
            </div>
        </div>
    </div>
    )}
}    
export default  ProdReview;
