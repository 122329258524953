//https://publico.transbank.cl/productos-y-servicios/soluciones-para-ventas-internet/webpay-cl#fragment-0-pedn
const tbkCheckOutToken = '/mod_tbk/free/gettoken'
const tbkCheckOutTokenRoute = '/mod_tbk/gettoken'
const tbkPagoRoute = '/mod_tbk/tbkPago'
const tbkPago = '/mod_tbk/free/tbkPago'

module.exports = {  
	tbkCheckOutToken,
	tbkCheckOutTokenRoute,
	tbkPagoRoute,
    tbkPago,
}
