import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
//import BarraMenuVertical from "global/components/Page/Header_01/barraMenu/BarraMenuVertical02";
//import BarraMenuVertical from "modEcomerce/ventas/header/BarraHeader00";
class BrHeadMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    render = () => {
        // console.log("render 03Head",this.data.regMenHead)
        if (this.data.regMenHead.length == 0) return (<></>)
        return (
            <div className="header-horizontal-menu">
                <ul className="menu-content">
                    {this.data.regMenHead && this.data.regMenHead.map((menuNiv00, index00) => {
                        if (menuNiv00.idpadre == -1 && index00 > -1)
                            return (<li key={index00}
                                className="menu-dropdown"
                                style={{ color: 'red' }}>
                                <a onClick={() => this.props.navigate(menuNiv00.url)}
                                    style={{ color: this.data.colorLinea }}>
                                    {menuNiv00.descripcion}&nbsp;
                                    {menuNiv00.hijos > 0 && (<i className="ion-ios-arrow-down"></i>)}
                                </a>
                                <ul className="main-sub-menu">
                                    {this.data.regMenHead.map((menuNiv11, index11) => {
                                        if (menuNiv00.idmenu == menuNiv11.idpadre)
                                            return (
                                                <li key={index11} className="menu-dropdown position-static">
                                                    <a  onClick={() => this.props.navigate(menuNiv11.url)}>
                                                        {menuNiv11.descripcion}
                                                        {menuNiv11.hijos > 0 && (<i className="ion-ios-arrow-right"></i>)}
                                                    </a>
                                                    <ul className="main-sub-menu main-sub-menu-2">
                                                        {this.data.regMenHead.map((menuNiv22, index22) => {
                                                            if (menuNiv11.idmenu == menuNiv22.idpadre)
                                                                return (<li>
                                                                    <a  onClick={() => this.props.navigate(menuNiv22.url)}>
                                                                        {menuNiv22.descripcion}
                                                                    </a>
                                                                </li>)
                                                        })}

                                                    </ul>
                                                </li>
                                            )
                                    })}
                                </ul>
                            </li>)
                    })}
                </ul>
            </div>
        )
    }
}
export default WithNavigate(BrHeadMenu);  