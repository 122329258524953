import React, { Component } from 'react';
import ApiTbk from "global_tbk/ApiTbk";

import './CheckOutDatosOrder.css';
class CheckOutFPayOrder extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    checkOutOrder = () => {
        window.location.href=ApiTbk.tbkCheckOutTokenRoute
    }
    render = () => {
        return (
            <div className="col-12">

                <div className="your-order-area">
                    <h3>Su pedido</h3>
                    <div className="your-order-wrap gray-bg-4">
                        <div className="your-order-product-info">
                            <div className="your-order-top">
                                <ul>
                                    <li>Producto</li>
                                    <li>Cantidad</li>
                                    <li>Total</li>
                                </ul>
                            </div>
                            <div className="your-order-middle">
                                <ul>
                                    {this.data.dataObj.regCarro && this.data.dataObj.regCarro.map((producto, index) => {
                                        return (<li>
                                            <span className="order-middle-left">{producto.nombre}</span>
                                            <span className="order-middle-right">{producto.cantidad}</span>
                                            <span className="order-price">${producto.preciototal}</span>
                                        </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {/*<div className="your-order-bottom">
                            <ul>
                                <li className="your-order-shipping">Shipping</li>
                                <li>Free shipping</li>
                            </ul>
                        </div>*/}
                            <div className="your-order-total">
                                <ul>
                                    <li className="order-total">Total</li>
                                    <li>${this.data.dataObj.regTotal[0].totalpagarformat}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="payment-method">
                            <div className="payment-accordion element-mrg">
                                <div className="panel-group" id="accordion">
                                    <div className="panel payment-accordion">
                                        <div className="panel-heading" id="method-one">
                                            <h4 className="panel-title">
                                                <a data-bs-toggle="collapse" href="#method1">
                                                    Forma de Pago
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="method1" className="panel-collapse collapse show" data-bs-parent="#accordion">
                                            {this.data.dataObj.regFPpago && this.data.dataObj.regFPpago.map((fpago, index) => {
                                                return (<div className="panel-body">
                                                    <p><input id={fpago.codigo} value={fpago.codigo}
                                                        name="paymentmethod" type="radio" data-minimum="50.0"
                                                        checked={fpago.checked} />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;{fpago.nombre}
                                                    </p>
                                                </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Place-order mt-25">
                        <a className="btn-hover" onClick={this.checkOutOrder}>Realizar Pago</a>
                    </div>
                </div>
            </div>
        )
    }

}
export default CheckOutFPayOrder;