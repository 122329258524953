import React, { Component } from 'react';

class ProdDescripcion extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (
            <div className="tab-pane fade show active" id="pd-desc">
                <div className="pd-tab__desc1">
                    <div className="u-s-m-b-308"
                        dangerouslySetInnerHTML={{ __html: this.data.text_info }} />
                    <br />**************<br />
                    {/*<div className="u-s-m-b-30"><iframe src="https://www.youtube.com/embed/qKqSBm07KZk" allowfullscreen></iframe></div>
        <div className="u-s-m-b-30">
            <ul>
                <li><i className="fas fa-check u-s-m-r-8"></i>

                    <span>Buyer Protection.</span></li>
                <li><i className="fas fa-check u-s-m-r-8"></i>

                    <span>Full Refund if you don't receive your order.</span></li>
                <li><i className="fas fa-check u-s-m-r-8"></i>

                    <span>Returns accepted if product not as described.</span></li>
            </ul>
           
  </div>*/}
                </div>
            </div>
        )
    }
    //<Rating rating={{" allRating, setAllRating" }}/>

}
export default ProdDescripcion;
