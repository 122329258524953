import React, { Component } from 'react';
//import LoginSignOut from "./LoginSignOut";
//import LoginSignIn from "./LoginSignIn";
//import ApiAuth from "global/auth";
import "./PreLoaderCircle.css"
class PreLoader extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (
            <div id="nb-global-spinner"  className="spinner">
                <div  className="blob blob-0"></div>
                <div  className="blob blob-1"></div>
                <div  className="blob blob-2"></div>
                <div  className="blob blob-3"></div>
                <div  className="blob blob-4"></div>
                <div  className="blob blob-5"></div>
            </div>

        )
    }

}
export default PreLoader;
