import React,{Component} from 'react';
import './CheckOutDespBilling.css';  
class CheckOutDatosBilling extends Component{
    constructor(props) {
        super(props);
        this.data=props;
    }
    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;

        var stJs = ''
        stJs += '$(".checkout-despa").on("click", function(e) {'
        stJs += '    $(".open-despa").slideToggle(1000);'
        stJs += '});'

        stJs += '$(".checkout-retiro").on("click", function(e) {'
        stJs += '    $(".open-retiro").slideToggle(1000);'
        stJs += '});'

        stJs += '$(".checkout-cuenta").on("click", function(e) {'
        stJs += '    $(".open-cuenta").slideToggle(1000);'
        stJs += '});'
        s.innerHTML = stJs;
        this.instance.appendChild(s);
    }    

    render =()=>{
        return ( 
        <div className="col-lg-7">
            <div ref={el => (this.instance = el)} />
                <div className="billing-info-wrap">
                    <h3>Detalles de Entrega</h3>
                    <div className="different-address  mt-30px">
                        <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="billing-info mb-20px">
                                    <label>Nombres</label>
                                    <input  name="txZZNombres" id="txZZNombres" type="text"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccnombres}
                                        />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="billing-info mb-20px">
                                    <label>Apellidos</label>
                                    <input   name="txZZApellidos" id="txZZApellidos" type="text" 
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccapellidos} 
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="billing-info mb-20px">
                                    <label>Nombre Empresa</label>
                                    <input  name="txZZEmpresa" id="txZZEmpresa" type="text" 
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccempresa}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                    <br/>
                    <div className="col-lg-12">
                        <input name="txChEnvio" id="txChDesp" className="checkout-despa" type="radio"/>
                        <label>&nbsp;  Despacho a Domicilio</label>
                    </div>


                    <div className="checkout-account-toggle open-despa mb-30">
                            <div className="row">

                                <div className="col-lg-12">
                                        <div className="billing-info mb-20px">
                                            <label>Dirección</label>
                                            <input  name="txZZDir1" id="txZZDir1" className="billing-address" 
                                                    placeholder="House number and street name" type="text"
                                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccdir1}
                                             />
                                            <input  name="txZZDir2" id="txZZDir2" 
                                                    placeholder="Apartamento, suite, unidad, etc." type="text" 
                                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccdir2}
                                                    />
                                        </div>
                                    </div>
                                    <div className="checkout-despaxx mb-30px">
                                        <div className="different-address  mt-30px">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="billing-select mb-20px">
                                                        <label>País</label>
                                                        <select  name="cbZZPais" id="cbZZPais" 
                                                            value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccpais}
                                                        >
                                                            <option>Selecione País</option>
                                                            <option>Chile</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="billing-info mb-20px">
                                            <label>Ciudad</label>
                                            <input   name="txZZCiudad" id="txZZCiudad" type="text" 
                                            value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccciudad}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20px">
                                            <label>Comuna</label>
                                            <input   name="txZZComuna" id="txZZComuna" type="text" 
                                                     value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].cccomuna}
                                                     />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20px">
                                            <label>Código Postal / ZIP</label>
                                            <input   name="txZZPostal" id="txZZPostal" type="text" 
                                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccpostal}
                                                    />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20px">
                                            <label>Teléfono</label>
                                            <input   name="txZZPhone" id="txZZPhone"  type="text" 
                                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccphone}
                                                    />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20px">
                                            <label>Email</label>
                                            <input   name="txZZEmail" id="txZZEmail"  type="text" 
                                                     value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ccemail}/>
                                        </div>
                                    </div>



                            </div>
                    </div>

                    <div className="checkout-retiroxx mb-30px">
                        <input name="txChEnvio" id="txChRet" className="checkout-retiro" type="radio" />
                        <label>&nbsp;  Retiro en Tienda</label>
                    </div>

                    <div className="checkout-account-toggle open-retiro mb-30">
                        <button className="btn-hover checkout-btn" type="submit">Retiro en Tienda</button>
                    </div>



                    <div className="different-address  mt-30px">
                        <div className="additional-info-wrap">
                            <h4>Información Adicional</h4>
                            <div className="additional-info">
                                <label>Comentarios</label>
                                <textarea  name="txZZComenta" id="txZZComenta" 
                                    placeholder="Notas sobre su pedido, p. notas especiales para la entrega. " 
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].cczzcomenta}
                                    ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}

}    
export default  CheckOutDatosBilling;