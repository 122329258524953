import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import "./Barra/BrMeVertNiv.css"
import ApiGl from "global/ApiGlobal";



//{!this.data && <Pag404EcoHost/>}
/*
import BarraStart from "global/components/Page/Header/Barra/BarraNav01Start";
import BarraStartMob from "global/components/Page/Header/Barra/BarraNav01StartMob";

import BarraTop from "global/components/Page/Header/Barra/BarraNav02Top";
import BarraTopCarro from "global/components/Page/Header/Barra/BarraNav02TopCarro";

import BarraMenVer from "global/components/Page/Header/Barra/BarraNav03Men";
import BarraMenuHeadMob from "global/components/Page/Header/Barra/BarraNav02TopMob";
import BarraMenuCateMob from "global/components/Page/Header/Barra/BarraNav03MenuCateMob";
*/

import BrHeadMenu from "./Barra/BrHeadMenu";

import BrMeVertNiv from "./Barra/BrMeVertNiv";
import BrMeVertPag from "./Barra/BrMeVertPage";


class HFrBarraMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
  
    componentDidMount() {
        if (this.data.dataObj.regCatego.length == 0) return;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        var stJs = '   $(".header-menu-vertical .menu-title").on("click", function(event) {            $(".header-menu-vertical .menu-content").slideToggle(500);        });'
        stJs += '$(".menu-content").each(function() {'
        stJs += 'var $ul = $(this),'
        stJs += '        $lis = $ul.find(".menu-item:gt(8)"),'
        stJs += '        isExpanded = $ul.hasClass("expanded");'
        stJs += '    $lis[isExpanded ? "show" : "hide"]();'

        stJs += '    if ($lis.length > 0) {'
        stJs += '        $ul.append('
        stJs += '            $('
        stJs += '                \'<li key="1" className="expand">\' +'
        stJs += '                   (isExpanded ? \'<a href="javascript:;"><span><i className="ion-android-remove"></i>Close Categories</span></a>\' : \'<a href="javascript:;"><span><i className="ion-android-add"></i>More Categories</span></a>\') +'
        stJs += '                "</li>"'
        stJs += '            ).on("click", function(event) {'
        stJs += '                var isExpanded = $ul.hasClass("expanded");'
        stJs += '                event.preventDefault();'
        stJs += '                $(this).html(isExpanded ? \'<a href="javascript:;"><span><i className="ion-android-add"></i>More Categories</span></a>\' : \'<a href="javascript:;"><span><i className="ion-android-remove"></i>Close Categories</span></a>\');'
        stJs += '                $ul.toggleClass("expanded");'
        stJs += '                $lis.toggle(300);'
        stJs += '            })'
        stJs += '        );'
        stJs += '    }'
        stJs += '});'
        s.innerHTML = stJs;
        this.instance.appendChild(s);
    }
    render = () => {
        //console.log("BarraMenu", this.data)
        return (
            <div className="header-menu bg-red sticky-nav d-lg-block d-none padding-0px menu_fixed animated fadeInDown"
                style={{ backgroundColor: this.data.regBar.html_bgcolor }}>
                <div ref={el => (this.instance = el)} />
                <div className="container">
                    <div className="row" style={{ color: this.data.regBar.html_txcolor }}>


                        {this.data.dataObj.regCatego.length != 0
                            && this.data.dataObj.regEcoHost.html_catego_dsp == 1
                            && (<div className="col-lg-3"><h4 className="menu-title"></h4></div>)}

                        {this.data.dataObj.regCatego.length != 0
                            && this.data.dataObj.regEcoHost.html_catego_dsp == 2
                            && (<BrMeVertPag
                                categoList={this.data.dataObj.regCatego}
                                colorHeadLine={this.data.regBar.html_bgcolor}
                                colorTextLine={this.data.regBar.html_txcolor}
                                pDataBarra={this.data.dataObj.pDataBarra} />)}
                        {this.data.dataObj.regCatego.length != 0
                            && this.data.dataObj.regEcoHost.html_catego_dsp == 3
                            && (<BrMeVertNiv
                                categoList={this.data.dataObj.regCatego}
                                colorHeadLine={this.data.regBar.html_bgcolor}
                                colorTextLine={this.data.regBar.html_txcolor}
                                pDataBarra={this.data.dataObj.pDataBarra} />)}

                        <div className="col-lg-9" style={{ color: this.data.regBar.html_txcolor }} >
                            <BrHeadMenu
                                regMenHead={this.data.dataObj.regMenHead}
                                colorLinea={this.data.regBar.html_txcolor} />


                            <div className="contact-link contact-link-nro">
                                <a title="Carrito"
                                    href="#offcanvas-cart"
                                    className="bag offcanvas-toggle"
                                    data-number={this.data
                                        && this.data.dataObj.regTotal
                                        && this.data.dataObj.regTotal[0].totcarroitem
                                    }>
                                    <i className="icon-shopping-cart"></i>
                                    <span style={{ color: this.data.regBar.html_txcolor }}>
                                        {this.data && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totalpagarformat && ("$" + this.data.dataObj.regTotal[0].totalpagarformat)}
                                    </span>
                                </a>
                            </div>
                            <div className="contact-link contact-link-nro"  >
                                <a onClick={() => this.props.navigate(ApiGl.deseosRoute)}
                                    title="Lista de Deseos"
                                    className="heart offcanvas-toggle"
                                    data-number={this.data  && this.data.dataObj.regTotal[0].totheart}>
                                    <i className="icon-heart"></i>
                                </a>
                            </div>

                            {this.data.dataObj.regEcoHost.html_telefono &&
                                (<div className="contact-link contact-linkpo"
                                    onClick={() => { window.location.href = "tel:" + this.data.dataObj.regEcoHost.html_telefono }}
                                    style={{
                                        color: this.data.regBar.html_txcolor
                                        , width: '39px'
                                        , height: '39px'
                                        , cursor: "pointer"
                                        , background: `url('/img/icon_header_phone.png')no-repeat 100% 100%`
                                    }}
                                >
                                    <a href={"tel:" + this.data.dataObj.regEcoHost.html_telefono}
                                        style={{ color: this.data.regBar.html_txcolor, cursor: "pointer" }}
                                        target="_blank"
                                    >{/*this.data.dataObj.regEcoHost.html_telefono*/}</a>
                                </div>)
                            }
                            {this.data.dataObj.regEcoHost.html_wasap &&
                                (<div className="contact-link contact-linkwa"
                                    style={{
                                        color: this.data.regBar.html_txcolor
                                        , cursor: "pointer"
                                        , background: `url('/img/icon_header_wasap.png')no-repeat 10% 10%`
                                        , width: '39px'
                                        , height: '39px'
                                        , top: '7px'
                                    }}
                                >
                                    <a href={"https://wa.me/" + this.data.dataObj.regEcoHost.html_wasap + "?text=Me gustaría%20realizar%20una%20consulta"}
                                        style={{ color: this.data.regBar.html_txcolor, cursor: "pointer" }}
                                        target="_blank"
                                    >{/*this.data.dataObj.regEcoHost.html_wasap*/}</a>
                                </div>)
                            }



                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default WithNavigate(HFrBarraMenu);



