// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-inner {
    padding: 30px;
    background-color: #fff;
  }
  @media only screen and (max-width: 767px) {
    .container-inner {
      padding: 30px 10px;
    }
  }
  .mb-60px {
    margin-bottom: 60px;
  }
 `, "",{"version":3,"sources":["webpack://./src/modEcomerce/ventas/checkout/CheckOutFPay.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;EACA;IACE;MACE,kBAAkB;IACpB;EACF;EACA;IACE,mBAAmB;EACrB","sourcesContent":[".container-inner {\r\n    padding: 30px;\r\n    background-color: #fff;\r\n  }\r\n  @media only screen and (max-width: 767px) {\r\n    .container-inner {\r\n      padding: 30px 10px;\r\n    }\r\n  }\r\n  .mb-60px {\r\n    margin-bottom: 60px;\r\n  }\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
