import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import "./BrHeadSearch.css"

//onClick={() => { window.location.href = ApiGl.deseosRoute }}
class BrHeadSearch extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    render = () => ( //bg-white 
        <div className="header-top ptb-30px d-lg-block d-none"
            style={{ backgroundColor: this.data.regBar.html_bgcolor }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-3 d-flex">
                        <div className="logo align-self-center">
                            <a onClick={() => this.props.navigate("/")}>
                                <img className="img-responsive"
                                    src={process.env.REACT_APP_URL_DATA + "/"
                                        + ApiAuth.getEmpresa()
                                        + "/img/logo_empresa.png"}
                                    alt="logo.png"
                                    width="50px" height="50px"
                                /></a>
                        </div>
                    </div>
                    <div className="col-md-9 align-self-center">
                        <div className="header-right-element d-flex justify-content-between">
                            <div className="search-element media-body me-20px">
                                <form className="d-flex" action={this.data.pDataBarra.pSearchRoute}>
                                    <input type="text" placeholder="Busqueda Producto ..." />
                                    <button><i className="icon-search"></i></button>
                                </form>
                            </div>
                            <div className="header-tools d-flex">
                                <div className="contact-link contact-link-nro">
                                    <a onClick={() => this.props.navigate(ApiGl.deseosRoute)}
                                        title="Lista de Deseos"
                                        className="heart offcanvas-toggle"
                                        data-number={this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totheart}>
                                        <i className="icon-heart"></i>
                                    </a>
                                </div>
                                <div className="contact-link contact-link-nro"  >
                                    <a title="Carrito"
                                        href="#offcanvas-cart"
                                        className="bag offcanvas-toggle"
                                        data-number={this.data.dataObj
                                            && this.data.dataObj.regTotal
                                            && this.data.dataObj.regTotal[0].totcarroitem
                                        }>
                                        <i className="icon-shopping-cart"></i>
                                        <span style={{ color: this.data.regBar.html_txcolor }}>${this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totalpagarformat}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
export default WithNavigate(BrHeadSearch);  