import React,{Component} from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";
import './CheckOutDespOrder.css';  
class CheckOutDatosOrder extends Component{
    constructor(props) {
        super(props);
        this.data=props;
    }
    checkOutOrder=()=>{
        var stData = { 
            /*
            ,txCCEmail:document.getElementById("txCCEmail").value
            ,txCCClave:document.getElementById("txCCClave").value
            */
             txZZComenta:document.getElementById("txZZComenta").value
            ,txZZNombres:document.getElementById("txZZNombres").value
            ,txZZApe:document.getElementById("txZZApellidos").value
            ,txZZEmp:document.getElementById("txZZEmpresa").value
            ,cbZZPais:document.getElementById("cbZZPais").value
            ,txZZDir1:document.getElementById("txZZDir1").value
            ,txZZDir2:document.getElementById("txZZDir2").value
            ,txZZCiudad:document.getElementById("txZZCiudad").value
            ,txZZComuna:document.getElementById("txZZComuna").value
            ,txZZPostal:document.getElementById("txZZPostal").value
            ,txZZPhone:document.getElementById("txZZPhone").value
            ,txZZEmail:document.getElementById("txZZEmail").value
            }

            this.post(Api.checkOutDatoCompraDespSave,stData).then(data=>{
            if(data.ok) this.props.navigate(Api.checkOutFpayRoute)
        })
    }
    render =()=>{
        return ( 
            <div className="col-lg-5 mt-md-30px mt-lm-30px ">
            
            <div className="your-order-area">
                <h3>Su pedido</h3>
                <div className="your-order-wrap gray-bg-4">
                    <div className="your-order-product-info">
                        <div className="your-order-total">
                            <ul>
                                <li className="order-total">Total</li>
                                <li>${this.data.dataObj.regTotal[0].totalpagarformat}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="Place-order mt-25">
                    <a className="btn-hover" onClick={this.checkOutOrder}>Guardar Datos Envio</a>
                </div>
            </div>
        </div>        
        )}

}    
export default  WithNavigate(CheckOutDatosOrder);