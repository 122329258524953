import React,{Component} from 'react';
//import Api from "modEcomerce/ApiEcomerce";
//import './CheckOutPrd.css'; // 
import Calendar from 'global/components/Page/Calendar/Calendario_01'
class FDespacho extends Component{
    constructor(props) {
        super(props);
        this.data=props;
    }
  
    render =()=>{
                    return (<>  
                    <Calendar titulo="Mis Turnos"/>
                    </>)
                }
  
}    
export default  FDespacho;


