import React, { Component } from 'react';
import Api from "global/ApiGlobal";
/*
import BarraHor01 from './barraMenu/BarraMenuHor02Lineal';
import BarraHor02 from './barraMenu/BarraMenuHor01Column';
*/
class BrMeVertPage extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    render = () => (
        <div className="col-lg-3">
            {this.data.categoList.length == 0
                && (<h4 className="menu-title"
                    style={{ backgroundColor: this.data.colorHeadLine }}>
                </h4>)
            }

            {(this.data.categoList.length > 0)
                && (<><div className="header-menu-vertical">

                    <h4 className="menu-title"
                        style={{ backgroundColor: this.data.colorHeadLine,color: this.data.colorTextLine }}>
                        {this.data.categoList[0] && this.data.categoList[0].descripcion}
                    </h4>
                    <ul className="menu-content display-none">
                        {this.data.categoList.map((menuNiv00, index00) => {
                            if (menuNiv00.idpadre == this.data.categoList[0].idcatego && index00 > -1)
                                return (<li key={"k" + index00} className="menu-item">
                                    <a href={Api.categoriaRoute + "?refCat=" + menuNiv00.idcatego}>{menuNiv00.descripcion}
                                        {menuNiv00.hijos > 0 && (<i className="ion-ios-arrow-right"></i>)}
                                    </a>
                                    {menuNiv00.hijos > 0 && (<ul className="sub-menu flex-wrap overflow-hidden">
                                        {this.data.categoList.map((menuNiv11, index11) => {
                                            if (menuNiv00.idcatego == menuNiv11.idpadre)
                                                return (<li className="col-md-4" key={"kli" + index11}>
                                                    <a href={Api.categoriaRoute + "?refCat=" + menuNiv11.idcatego}>
                                                        <span> <strong> {menuNiv11.descripcion}</strong></span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        {this.data.categoList.map((menuNiv22, index22) => {
                                                            if (menuNiv11.idcatego == menuNiv22.idpadre)
                                                                return (<li key={"klius" + index22}><a href={Api.categoriaRoute + "?refCat=" + menuNiv22.idcatego}>{menuNiv22.descripcion}</a></li>)
                                                        })}
                                                    </ul>
                                                </li>)

                                        })}
                                    </ul>
                                    )
                                    }
                                </li>)
                        })
                        }
                        {/*<li className="expand"><a href="javascript:;"><span><i className="ion-android-add"></i>More Categories</span></a></li>*/}
                    </ul>
                </div>
                </>)}
        </div>


    )
}
export default BrMeVertPage;