import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";
import ShowLoading from "global/components/ShowLoading";
import Carrusel from 'modEcomerce/ventas/components/carrusel/Carrusel_01';
import ProdDescripcion from '../ProdDescripcion'
import ProdAtributos from '../ProdAtributos'
import HFormUser from "global/components/Page/Forms/HFormUser";
import ProdReview from '../ProdReview'
import EstrellasRating from '../../estrellas/EstrellasRating'
import Corazon from '../../corazon/Corazon'

//import $ from 'jquery';
import './Producto_v3.css'
class ProductoV3 extends HFormUser {
    usuarioVar = null
    constructor(props) {
        super(props);
        this.addCarr = this.addCarr.bind(this)
        this.data = props;
        this.usuarioVar = this.context;
        this.state = {
            data: [],
            loading: true,
            idProducto: 0
        };
        //console.log("Header PageAA",this.props)     
    }
    componentDidMount() {
        //const query = new URLSearchParams(this.props.location.search);
        this.setState({ idProducto: this.props.params.idProducto })
        this.readApi(this.props.params.idProducto)

        //    this.loadJs();	
    }
    readApi(idMod) {
        this.post(Api.productoDetalle, { 'idKey': idMod })
            .then(data => { this.stateHarrys(this, data) })
    }
    
    addCarr(idProducto) {
        this.post(Api.addCarro, { 'idKey': idProducto })
            .then(data => {//this.stateHarrysRow(this,data)
            })
    }
    changeSelect = (stImg, index) => {
        var imgs = document.querySelectorAll('.product-one-image-select')
        //console.log(imgs)
        for (var count = 0; count < imgs.length; count++) {
            if (Number(count) === Number(index)) {
                imgs[count].classList.remove('no-selected')
                imgs[count].classList.add('selected')
                document.getElementById("keyImage").src = this.state.data.images[index].http_imagen
            } else {
                imgs[count].classList.add('no-selected')
                imgs[count].classList.remove('selected')
            }
        }
    }
    selectedTab = (stTab) => {
        //alert(stTab)
        // document.getElementById("stTab").active
    }
    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        const isLogged = true
        return (<>
            <div className="bg-white">
                <div className="top-product-contain"><h3>{this.state.data.titulo}</h3></div>
                <div className="container product-container d-flex">
                    <div className="select-image-container">
                        {
                            this.state.data.images.map((item, index) => {
                                if (item.idnro < 100)
                                    return <div className={`product-one-image-select ${index === 0 ? "selected" : "no-selected"}`}
                                        key={index}
                                        onClick={() => this.changeSelect(item.http_imagen, index)}
                                    >
                                        <img src={`${item.http_imagen}`} alt={`produto ${Date.now()}`} />
                                    </div>

                            })
                        }
                    </div>

                    <div className="the-image-selected-container">
                        <img id="keyImage" src={this.state.data.http_imagen} alt="product selected" />
                    </div>

                    <div className="information-about-product-container">
                        <div className="d-flex">
                            <div className="products-star-avaliation d-flex">
                                <div className="text-warning">
                                    <EstrellasRating rating={this.state.data.rating} />
                                </div>
                            </div>
                            <Corazon like={false} />

                        </div>
                        <h4 className="mb-3 mt-4"><b>{this.state.data.titulo}</b></h4>
                            <div className="content1" style={{ marginLeft: '15px', marginRight: '15px' }}
                                dangerouslySetInnerHTML={{ __html: this.state.data.text_descrip }}>
                            </div>
                            <br/>
                        <div>
                            <div className="avaliations-stars">{this.state.data.stock} Disponibles</div>
                            <span className="product-amount-rating">{this.state.data.calif_total} {this.state.data.calif_total < 2 ? "Calificación" : "Calificaciones"}  </span>
                        </div>

                        {/*<small className="text-decoration-line-through mt-2">${this.state.data.precio_old}</small>*/}
                        <div className="display-6 text-primary">$	{this.state.data.precio_max}
                            {/*<small className="text-success fs-5"> 7% OFF</small>*/}
                        </div>
                        {/*<div >ou 12x de R${"(Number(product.price) / 12).toFixed(2) "}</div>*/}

                        <div className="mt-5 mb-2 shadow">
                            <button onClick={()=>this.props.navigate(Api.checkOutRoute)}
                                className="btn btn-success form-control">
                                Comprar Ahora
                            </button>
                        </div>

                        <div className="d-flex add-to-card-container">
                            <select className="form-control w-25 me-2">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                                <option value="">5</option>
                                <option value="">6</option>
                                <option value="">7</option>
                                <option value="">8</option>
                                <option value="">9</option>
                            </select>
                            <div className="w-75">
                                <button className="btn btn-primary form-control shadow btn-add-to-cart"
                                    onClick={() => { this.addCarr(this.state.idProducto) }}>
                                    Agregar al Carro
                                </button>
                            </div>
                        </div>
                        <div className="d-flex add-to-card-container">
                            <button onClick={()=>this.props.navigate(Api.comprarRoute)}
                                className="btn btn-success form-control">
                                Seguir Comprando
                            </button>
                        </div>
                    </div>
                </div>

                <h3 className="container">Produtos Similares</h3>
                <Carrusel lPopular={this.state.data.popular}
                    clickLoad1={this.readApi} />


                {/*rating*/}

                {/************************************** */}
                <div className="m-4">
                    <ul className="nav nav-tabs" id="myTab">
                        <li className="nav-item">
                            <a href="#pd-desc" className="nav-link active" data-bs-toggle="tab">Descripción</a>
                        </li>
                        <li className="nav-item">
                            <a href="#pd-atr" className="nav-link" data-bs-toggle="tab">Atributos</a>
                        </li>
                        {/*<li className="nav-item">
                    <a href="#pd-tag" className="nav-link" data-bs-toggle="tab">Tags</a>
                </li>*/}
                        <li className="nav-item">
                            <a href="#pd-rev" className="nav-link" data-bs-toggle="tab">Review</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <ProdDescripcion text_info={this.state.data.text_info} />
                        <ProdAtributos lAtributos={this.state.data.atributos} />
                        {/*<ProdTags/>*/}
                        <ProdReview lComentarios={this.state.data.comentarios}
                            prodNombre={this.state.data.titulo}
                            rating={this.state.data.rating}
                        />
                    </div>
                </div>
            </div>
            {/************************************** */}
        </>
        )
    }
    //<Rating rating={{" allRating, setAllRating" }}/>

}
export default WithNavigate(ProductoV3);
