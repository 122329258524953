import React, { Component } from 'react';
import BarraSearch from "global/components/Page/Header/Barra/BrHeadSearch";

class HFrBarraSearch extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        //console.log("regbarGen", this.data.regBar)
        return (
            <>
                <BarraSearch
                    dataObj={this.data.dataObj}
                    key={'hrtthyt_' + 'index'}
                    pDataBarra={this.data.pDataBarra}
                    regBar={this.data.regBar}
                />
            </>
        )
    }
}

export default HFrBarraSearch;



