const headerTopNav = '/free/header/headertopnav'
//const WWWSecciones = '/global/free/www_secciones'
//const headerCategoryList = '/free/header/headercategorylist'
const headerCategoryAll = '/free/header/headercategoryall'
const headerCategoryProd = '/free/header/headerCategoryProd'
const productoDetalleRoute = '/modelo_detalle'
const productoDetalle = '/free/producto/modelo_detalle'
const categoriaRoute = '/carrito/categoria'
const comprarRoute = '/'
const addCarro='/free/producto/addcarrito'
const heartCarro='/free/producto/heartcarrito'
const checkOutRoute = '/carrito/checkout'
const checkOut = '/free/checkout/carrito/'
const checkOutDatoCompraFactRoute = '/carrito/datosCompraFact'
const checkOutDatoCompraFactSave = '/free/checkout/datoscomprafactsave'
const checkOutDatoCompraFactRead = '/free/checkout/datoscomprafactread'
const checkOutDatoCompraDespSave = '/free/checkout/datoscompradespsave'

const checkOutDatoCompraDespRoute = '/carrito/datosCompraDes'
const checkOutDatoCompraDesp = '/free/checkout/datoscompradesp'
const checkOutFpayRoute = '/free/checkout/datosCompraFpay'

const checkOutSearchRoute = '/carrito/search'
const checkOutHeartRoute = '/carrito/heart'


module.exports = {
	//WWWSecciones,

	checkOutSearchRoute,
	checkOutHeartRoute,

	comprarRoute,
	headerTopNav,

	checkOutDatoCompraFactRoute,
	checkOutDatoCompraFactSave,
	checkOutDatoCompraFactRead,
	checkOutDatoCompraDespSave,

	checkOutDatoCompraDespRoute,
	checkOutDatoCompraDesp,

	checkOutFpayRoute,


	headerCategoryAll,
	headerCategoryProd,

	productoDetalleRoute,
	productoDetalle,
	categoriaRoute,
	addCarro,
    heartCarro,
	checkOutRoute,
	checkOut,
}
