import React,{Component} from 'react';

class EstrellasRating extends Component{
	constructor(props) {
     super(props);
     this.data=props;
  } 
  render =()=>{
      //                         <i className="fas fa-star-half-alt"></i>
    return (  <>
            <i className={this.data.rating >= 1 ? "fas fa-star" : "far fa-star"}></i>
            <i className={this.data.rating >= 2 ? "fas fa-star" : "far fa-star"}></i>
            <i className={this.data.rating >= 3 ? "fas fa-star" : "far fa-star"}></i>
            <i className={this.data.rating >= 4 ? "fas fa-star" : "far fa-star"}></i>
            <i className={this.data.rating >= 5 ? "fas fa-star" : "far fa-star"}></i>
            </>
    )}
}    
export default  EstrellasRating;
