import React,{Component} from 'react';

class ProdAtributos extends Component{
	constructor(props) {
     super(props);
     this.data=props;
  } 
  render =()=>{
    return (  <div className="tab-pane fade show " id="pd-atr">
    <div className="pd-tab__atr">
        <br/>
        <div className="u-s-m-b-15">
            <h4><strong><b>INFORMACIÓN DEL PRODUCTO</b></strong></h4>
        </div>
        <hr/>
        <div className="u-s-m-b-15">
            <div className="pd-table gl-scroll">
                <table>
                    <tbody>
        {	this.data.lAtributos.map((atributo, index) => {
            return (<tr key={'hye'+index}>
                        <td>{atributo.descripcion}</td>
                        <td>{atributo.valor}</td>
                    </tr>
                    )
			})
		}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
    )}
}    
export default  ProdAtributos;
