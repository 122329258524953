import React from 'react';
import UserProvider from "global/context/user/UserProvider";
import HeaderPage from "./Header/Web/Header_V01";
import PreLoader from "./Header/PreLoaderCircle";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
//const Container = ({ history, ...props }) => {
//    <PreLoader />
const Container = ({ history }) => {
    //console.log("props:", props)
    return (
        <UserProvider>
            <HeaderPage id="harrysHeader"/>
            <MainRouterMod />
        </UserProvider>
    )
};
export default Container;
//{regPag.idobj == null && <>No Tiene página asignada</>}
//{regPag.idobj != null && <FrBody dataObj={this.state.data.dataObj} />}
//{this.state.data.dataObj.regPage.length == 0 && <MainRouterMod  />}

// {this.state.data.dataObj.regPage.map((regPag, iCol) => {
//     return (<>
//     </>)
// })}
