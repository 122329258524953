// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 700px) {
    .card_01 {
        margin-left: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/modEcomerce/ventas/components/ShoppingCard01.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@media (min-width: 700px) {\r\n    .card_01 {\r\n        margin-left: 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
