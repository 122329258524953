import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";

class Corazon extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = { like: this.data.like };
    }
    heartClick = (idModelo) => {
        this.post(Api.heartCarro, { 'idKey': idModelo })
            .then(data => {//this.stateHarrys(this,data)
                this.setState({like:this.state.like==1?0:1})
            })
    }
    render = () => {
        //onClick={() => this.data.likeClick()}
        const isLogged = true
        return (
            <div className="heartLike"
                onClick={() => this.heartClick(this.data.idModelo)}
                data-bs-toggle={isLogged ? "" : "modal"}
                data-bs-target={isLogged ? "" : "#staticBackdrop"}>
                <i style={{ left: '10px', top: '1px', color: 'red', fontSize: '30px' }}
                    className={this.state.like >= 1 ? "fas fa-heart" : "far fa-heart"}
                ></i>
            </div>

        )
    }
}
export default WithNavigate(Corazon);
