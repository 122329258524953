import React, { Component } from 'react';
import ApiTbk from "global_tbk/ApiTbk";
import Api from "modEcomerce/ApiEcomerce";
import ShowLoading from "global/components/ShowLoading";
const axios = require('axios');
//https://github.com/TransbankDevelopers
//https://github.com/TransbankDevelopers/transbank-sdk-php-webpay-example
//https://stackoverflow.com/questions/23327010/how-to-generate-unique-id-with-node-js
//   npm install uuid
class TbkCheckOut extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            data: [],
            loading: true,
        };
    }

    componentDidMount() { this.readApi() }
    readApi = () => {
        this.post(ApiTbk.tbkCheckOutToken, { 'idKey': 'refCat' })
            .then(data => { this.setState({ data: data, loading: false }); })
    }
    goSeguirComprando(idMod) {
        window.location.href = Api.comprarRoute
    }
    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        return (<>
            <button onClick={this.goSeguirComprando}
                className="btn btn-success form-control">
                Volver Comprar
            </button>
            <form method="POST"
                action={this.state.data.dataObj.url} >
                <input type="hidden1" name="token_ws"
                    value={this.state.data.dataObj.token} />
                <button type="submit"
                    className="btn btn-success form-control"
                >Pagar</button>
            </form>
            <br />
            {/*
   <div className="wrapper">
        <div className="gambo-Breadcrumb">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
                </div>


        <div className="all-product-grid">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-7">
                    <div id="checkout_wizard" className="checkout accordion left-chck145">
                        <div className="checkout-step">
                            <div className="checkout-card" id="headingFour">
                                <span className="checkout-step-number"><i
                                        className="fa-solid fa-bag-shopping i-t-checkout"></i></span>
                                <h4 className="checkout-step-title">
                                    Medio de Pago
                                    
                                </h4>
                            </div>
                            <div className="checkout-step-body">
                                <div className="payment_method-checkout">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="rpt100">
                                                <ul className="radio--group-inline-container_1">
                                                    <li>
                                                        <div className="radio-item_1">
                                                            <input id="cashondelivery1" value="cashondelivery"
                                                                name="paymentmethod" type="radio" data-minimum="50.0"
                                                                checked/>
                                                            <label for="cashondelivery1" className="radio-label_1">WebPay
                                                                Plus</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="radio-item_1">
                                                            <input id="card1" value="card" name="paymentmethod"
                                                                type="radio" data-minimum="50.0"/>
                                                            <label for="card1" className="radio-label_1">Transferencia
                                                                Bancaria</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-group return-departure-dts" data-method="cashondelivery"
                                                style={{display: 'block'}}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="pymnt_title radio-label_1"
                                                            style={{cursor: 'auto',width: 'fit-content'}}>
                                                            <img src="images/webpay-plus.png"
                                                                style={{width: '210px'}} 
                                                                alt="WebPayPlus"/>
                                                        </div>
                                                        <div>
                                                            <form method="POST">
                                                                <input className="next-btn16 hover-btn"
                                                                    style={{width: '-webkit-fill-available'}} name="submit"
                                                                    type="submit" value="Contratar"/>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group return-departure-dts main-form" data-method="card">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="pymnt_title mt-3 mb-0">
                                                            <h4 className="mb-0">Realice trasferencias Bancarias sin
                                                                intermediarios, de forma directa.</h4>
                                                            <p>Actualmente este medio de pago no esta disponible.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
****************************************************
<div className="col-lg-4 col-md-5">
                    <div className="pdpt-bg mt-0">
                        <div className="pdpt-title">
                            <h4>Resumen de Compra</h4>
                        </div>
                        <div className="right-cart-dt-body">

                            <div className="cart-item border_radius">
                                <div className="cart-product-img">
                                    <img src="./images/transbank/checkout.jpg"
                                        alt=""/>
                                    <div className="offer-badge">4% OFF</div>
                                </div>
                                <div className="cart-text">
                                    <h4>name time</h4>
                                    <div className="cart-item-price">$ precio
                                        <span>$precio</span>
                                    </div>
                                    <form method="POST">
                                        <input type="hidden" name="product_id" value="IDcampo"/>
                                        <input type="hidden" name="qty" value="1"/>
                                        <input type="hidden" name="acc" value="del"/>
                                        <button type="submit" className="cart-close-btn">
                                            <i className="uil uil-multiply"></i></button>
                                    </form>
                                </div>
                            </div>

                    
                            <div className="cart-alert-brouter">
                                <h4 className="alert-heading">Sin Productos Añadidos al Carrito</h4>
                                <p>Actualmente no has seleccionado planes de !</p>
                                <a href="/" className="next-btn16 hover-btn">Productos</a>
                            </div>


                        </div>
                        <div className="main-total-cart p-4">
                            <h2>Total a Pagar</h2>
                            <span>$ total</span>
                        </div>
                        <div className="payment-secure top-line-cart">
                        <form method="POST"
                              action={this.state.data.dataObj.url} >
                                <input type="hidden1" name="token_ws" 
                                    value={this.state.data.dataObj.token}/>
                                <input type="submit" value="Pagar"/>
                        </form>                        
                        </div>
                        <div className="payment-secure top-line-cart">
                            <form action={this.state.data.dataObj.url} method="POST">
                                <input name="order_id" type="hidden1" value=""/>
                                <input className="next-btn16 hover-btn" name="submit" type="submit"
                                    value="Pagar con webpay"/>
                            </form>
                        </div>
                    </div>
                    <a data-bs-toggle="collapse" href="#edit-number" className="promo-link45">¿Tienes un código de
                        promoción?</a>
                    <div className="collapse promo-link45" id="edit-number" style={{marginTop: '10px'}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="checkout-login">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="code-promo">
                                                    <input type="text" className="form-control"
                                                        placeholder="Introduzca un código promocional, si dispone de uno."/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <a className="chck-btn hover-btn" role="button" data-bs-toggle="collapse"
                                                    href="#otp-verifaction">Validar</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-safety-alerts">
                        <p><i className="uil uil-shield-check"></i>Pagos Seguros</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    */}


        </>)
    }

}
export default TbkCheckOut;


