import React,{Component} from 'react';
import ContainerWeb from "global/components/Page/CtnSysWeb";

class Pagina extends Component{
	constructor(props) {
     super(props);
     this.data=props;
   }
   render =()=>(<ContainerWeb />)  
}    
        
export default  Pagina;