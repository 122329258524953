import React,{Component} from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";
import './CheckOutDatosOrder.css';  
class CheckOutDatosOrder extends Component{
    constructor(props) {
        super(props);
        this.data=props;
    }
    checkOutOrder=()=>{
        var stData = { 
             txNombres :document.getElementById("txNombres").value
            ,txApe:document.getElementById("txApellidos").value
            ,txEmp:document.getElementById("txEmpresa").value
            ,cbPais:document.getElementById("cbPais").value
            ,txDir1:document.getElementById("txDir1").value
            ,txDir2:document.getElementById("txDir2").value
            ,txCiudad:document.getElementById("txCiudad").value
            ,txComuna:document.getElementById("txComuna").value
            ,txPostal:document.getElementById("txPostal").value
            ,txPhone:document.getElementById("txPhone").value
            }

            this.post(Api.checkOutDatoCompraFactSave,stData).then(data=>{
            if(data.ok) this.props.navigate(Api.checkOutDatoCompraDespRoute)
        })
    }
    render =()=>{
        return ( 
            <div className="col-lg-5 mt-md-30px mt-lm-30px ">
            
            <div className="your-order-area">
                <h3>Su pedido</h3>
                <div className="your-order-wrap gray-bg-4">
                    <div className="your-order-product-info">
                        <div className="your-order-top">
                            <ul>
                                <li>Producto</li>
                                <li>Cantidad</li>
                                <li>Total</li>
                            </ul>
                        </div>
                        <div className="your-order-middle">
                            <ul>
                            {this.data.dataObj.regCarro &&     this.data.dataObj.regCarro.map((producto, index) => {
                        return (<li>
                                  <span className="order-middle-left">{producto.nombre}</span> 
                                  <span className="order-middle-right">{producto.cantidad}</span> 
                                  <span className="order-price">${producto.preciototal}</span>
                                </li>
                            )})}
                            </ul>
                        </div>
                        {/*<div className="your-order-bottom">
                            <ul>
                                <li className="your-order-shipping">Shipping</li>
                                <li>Free shipping</li>
                            </ul>
                        </div>*/}
                        <br/>
                        <div className="your-order-total">
                            <ul>
                                <li className="order-total">Total</li>
                                <li>${this.data.dataObj.regTotal[0].totalpagarformat}</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="Place-order mt-25">
                    <a className="btn-hover" onClick={this.checkOutOrder}>Realizar Orden</a>
                </div>
            </div>
        </div>        
        )}

}    
export default  WithNavigate(CheckOutDatosOrder);