import React,{Component} from 'react';
import './CheckOutDatosBilling.css';  
class CheckOutDatosBilling extends Component{
    constructor(props) {
        super(props);
        this.data=props;
    }
  
    render =()=>{
        return ( 
            <div className="col-lg-7">
            <div ref={el => (this.instance = el)} />
                        <div className="billing-info-wrap">
                            <h3>Detalles de Facturación</h3>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Nombres</label>
                                        <input name="txNombres" id="txNombres" type="text" 
                                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].nombres}
                                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Apellidos</label>
                                        <input name="txApellidos" id="txApellidos" type="text" 
                                          value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].apellidos} 
                                          />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-20px">
                                        <label>Nombre Empresa</label>
                                        <input  name="txEmpresa" id="txEmpresa" type="text" 
                                           value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].empresa}
                                           />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-20px">
                                        <label>Pais</label>
                                        <select  name="cbPais" id="cbPais" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].pais}
                                                >
                                            <option>Seleccione País</option>
                                            <option>Chile</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-20px">
                                        <label>Dirección</label>
                                        <input  name="txDir1" id="txDir1" 
                                                className="billing-address" 
                                                placeholder="Casa Número y Calle" type="text" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].dir1}
                                                />
                                        <input  name="txDir2" id="txDir2" 
                                                placeholder="Apartment, suite, unit etc." type="text" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].dir2}
                                                />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-20px">
                                        <label>Ciudad</label>
                                        <input  name="txCiudad" id="txCiudad" type="text" 
                                                  value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ciudad}
                                                  />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Comuna</label>
                                        <input  name="txComuna" id="txComuna" type="text" 
                                            value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].comuna}
                                            />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Código Postal / ZIP</label>
                                        <input  name="txPostal" id="txPostal" type="text" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].postal}
                                                />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Teléfono</label>
                                        <input  name="txPhone" id="txPhone" type="text" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].phone}
                                                />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-20px">
                                        <label>Email</label>
                                        <input  name="txEmail" id="txEmail" type="text" 
                                                value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].email}
                                                />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
        )}

}    
export default  CheckOutDatosBilling;