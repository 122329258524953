import React, { Component } from 'react';
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import apiRuta from "global/utils/funRuta";
import Api from "modEcomerce/ApiEcomerce";
import './CheckOutPrd.css'; // 
import ShowLoading from "global/components/ShowLoading";
class CheckOut extends  HFormUser{
    componentDidMount() { this.readApi() }
    readApi = () => {
        this.post(Api.checkOut)
            .then(data => {
                this.setState({
                    data: data
                    , loading: false
                });
            })
    }

    restarUno = (idProducto) => {
        this.aplicarSumaResta(idProducto, -1)
    }
    sumarUno = (idProducto) => {
        this.aplicarSumaResta(idProducto, 1)
    }
    eliminarUno = (idProducto, sumarResta) => {
        this.aplicarSumaResta(idProducto, -999)
    }
    eliminarTodo = () => {
        this.aplicarSumaResta(0, -9999)
    }
    aplicarSumaResta = (idProducto, sumarResta) => {
        this.setState({loading:true})
        this.post(Api.addCarro, { 'idKey': idProducto, 'sumaresta': sumarResta })
            .then(data => {
                //window.location.href = Api.checkOutRoute
                //this.props.navigate(Api.checkOutRoute)
                 this.context.dataUser.dataHeader.dataObj.regTotal=data.dataObj.regTotal
                 this.context.dataUser.dataHeader.dataObj.regCarro=data.dataObj.regCarro
                //this.context.dataUser.dataHeader.dataObj=data.dataObj
                this.state.data.dataObj.regCarro=data.dataObj.regCarro
                apiRuta.setStateGlb(this)
                this.setState({loading:false})
            })
    }


    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        return (
            <div className="col-12">
                <div className="all-product-grid">
                    <div className="container1">
                        <div className="row">
                            <div className="col-lg-12 col-md-7">
                                <div id="checkout_wizard"
                                    className="checkout accordion left-chck145">
                                    <div className="checkout-step">
                                        <div className="pdpt-title">
                                            <h3>Resumen de Compra    1/4</h3>
                                        </div>
                                        {/*******************************************************************  */}
                                        <div className="cart-main-area mb-60px">
                                            <div className="container1">
                                                <div className="container-inner">
                                                    <h3 className="cart-page-title">Tus Productos del Carrito</h3>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <form action="#">
                                                                <div className="table-content table-responsive cart-table-content">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Imagen</th>
                                                                                <th>Nombre</th>
                                                                                <th>Precio</th>
                                                                                <th>Cantidad</th>
                                                                                <th>Subtotal</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {this.state.data.dataObj.regCarro && this.state.data.dataObj.regCarro.map((producto, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td className="product-thumbnail">
                                                                                            <a href="#">
                                                                                                <img className="img-responsive1"
                                                                                                    src={producto.http_imagen}
                                                                                                    width="100px" height="100px"
                                                                                                    alt="" /></a>
                                                                                        </td>
                                                                                        <td className="product-name"><a href="#">{producto.nombre}</a></td>
                                                                                        <td className="product-price-cart"><span className="amount">${producto.preciovta}</span></td>
                                                                                        <td className="product-quantity">
                                                                                            <div className="cart-plus-minus">
                                                                                                <div className="dec qtybutton" onClick={() => { this.restarUno(producto.idmodelo) }}>-</div>
                                                                                                <input className="cart-plus-minus-box"
                                                                                                    type="text"
                                                                                                    name="qtybutton"
                                                                                                    value={producto.cantidad} />
                                                                                                <div className="inc qtybutton" onClick={() => { this.sumarUno(producto.idmodelo) }}>+</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="product-subtotal">${producto.preciototal}</td>
                                                                                        <td className="product-remove">
                                                                                            {/*<a href="#"><i className="fa fa-pencil-alt"></i></a>*/}
                                                                                            <a onClick={() => { this.eliminarUno(producto.idmodelo) }}><i className="fa fa-trash"></i></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="cart-shiping-update-wrapper">
                                                                            <div className="cart-shiping-update">
                                                                                <a onClick={() => this.props.navigate(Api.comprarRoute)}
                                                                                >Continuar Comprando</a>
                                                                            </div>
                                                                            <div className="cart-shiping-update">
                                                                                <a onClick={() => { this.eliminarTodo() }}>Limpiar Canasta</a>
                                                                            </div>
                                                                            <div className="cart-clear">
                                                                                <a onClick={() => this.props.navigate(Api.checkOutDatoCompraFactRoute)}>
                                                                                    ${this.state.data.dataObj.regTotal[0].totalpagarformat}
                                                                                    Comprar
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="row">
                                                                {/*<CheckOutCostShip dataObj={this.state.data.dataObj}/>*/}
                                                                {/*<CheckOutCupon    dataObj={this.state.data.dataObj}/>*/}
                                                                {/*<CheckOutTotal    dataObj={this.state.data.dataObj}/>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*******************************************************************  */}`
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default WithNavigate(CheckOut);


