import React, { Component } from 'react';
import Api from "modEcomerce/ApiEcomerce";
import Card_01 from '../shoping_card/Card_01_card'
import Card_02 from '../shoping_card/Card_02_single'
import Card_03 from '../shoping_card/Card_03_text'
import './Carrusel_01.css'
class Carrusel extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    componentDidMount() {
        var next = document.querySelectorAll(".next-carousel")
        var prev = document.querySelectorAll(".prev-carousel")
        var scrollCarousel = document.querySelectorAll(".carousel-scroll")
        next[0].addEventListener("click", () => {
            scrollCarousel[0].scrollBy(400, 0)
        })
        prev[0].addEventListener("click", () => {
            scrollCarousel[0].scrollBy(-400, 0)
        })
    }

    render = () => {
        
        return (<div key='bt01j2978' className="PrimaryCarousel container">
                    <button key='bt01jh1' className="material-icons-outlined mov-carousel next-carousel">arrow_forward_ios</button>
                    <button key='bt01jhd' className="material-icons-outlined mov-carousel prev-carousel">arrow_back_ios</button>
                    <div key='bt01jh22' className="carouselStore carousel-scroll">
                        {this.data.lPopular.map((product, index) => {
                            return (<>
                            {product.html_card_type == 1 &&
                                (<Card_01 producto={product}
                                    key={'loue01' + index}
                                    clickLoad={this.data.clickLoad} />)}
                            {product.html_card_type == 2 &&
                                (<Card_02 producto={product}
                                    key={'loue02' + index}
                                    clickLoad={this.data.clickLoad} />)}
                            {product.html_card_type == 3 &&
                                (<Card_03 producto={product}
                                    key={'loue03' + index}
                                    clickLoad={this.data.clickLoad} />)}
                        </>)})
                        }
                        <div key='bt01jendhd'className="endCarousel"></div>
                    </div>
                </div>
        )
    }

}
export default Carrusel;
